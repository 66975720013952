import request from '@/utils/request'
const BACK_API = '/back/api'
const ADMIN_PATH = `${BACK_API}`

const listData = (params) => {
  return request({
    url: `${ADMIN_PATH}/admin/system/dict/data/`,
    method: 'get',
    params: params
  })
}

const addReceipt = (params) => {
  return request({
    url: `${ADMIN_PATH}/purchase/order/`,
    method: 'post',
    data: params
  })
}

const getPurchaseList = (params) => {
  return request({
    url: `${ADMIN_PATH}/purchase/order/`,
    method: 'get',
    params: params
  })
}

const getPurchase = (id) => {
  return request({
    url: `${ADMIN_PATH}/purchase/order/${id}/`,
    method: 'get'
  })
}

const getDept = (id) => {
  return request({
    url: `${ADMIN_PATH}/admin/permission/dept/${id}/`,
    method: 'get'
  })
}

export {
  listData,
  addReceipt,
  getPurchaseList,
  getPurchase,
  getDept
}

