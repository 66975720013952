<template>
  <vp-base-layout
    page-title="매입내역"
    page-default-back-link="/">
    <vp-template-list
      :datas="datas"
      :total="pageInfo.total"
      @detail="onDetail">
      >
      <template #top>
        <ion-list lines="none">
          <ion-item>
            <ion-label>오늘 매입</ion-label>
            <ion-text
              slot="end"
              color="primary">
              {{ $filters.countTxt(cnt1) }}
            </ion-text>
          </ion-item>
          <ion-item>
            <ion-label>수거 후 입금대기</ion-label>
            <ion-text
              slot="end"
              color="primary">
              {{ $filters.countTxt(cnt2) }}
            </ion-text>
          </ion-item>
        </ion-list>
        <ion-list>
          <ion-item>
            <ion-label>매입내역 (총 {{ $filters.countTxt(pageInfo.total) }})</ion-label>
            <ion-icon
              :icon="funnelOutline"
              @click="presentActionSheet"
              slot="end" />
          </ion-item>
        </ion-list>
      </template>
      <template #contents="{ item }">
        <ion-label :color="item.order_state_code === '1'? 'dark' : item.order_state_code === '2' ? 'primary': 'medium'">
          <h2>{{ item.acc_nm + '/' + item.order_tel_no + '/' + (item.order_state_code === '1'? '접수' : item.order_state_code === '2' ? '수거': '완료') }}</h2>
          <p>{{ item.description }}</p>
          {{ item.order_date }}
        </ion-label>
      </template>
    </vp-template-list>
  </vp-base-layout>
</template>

<script setup>
//---------------------------------------------------------------------------------------------------------------------
// import
//---------------------------------------------------------------------------------------------------------------------
import { IonList, IonItem, IonLabel, IonText, IonIcon, useIonRouter, actionSheetController } from '@ionic/vue'
import { funnelOutline } from 'ionicons/icons'
import { ref, inject, onMounted } from 'vue'
import VpTemplateList from '@/components/templates/list/VpTemplateList.vue'
import { getPurchaseList } from '@/api/mintit/agency'

//---------------------------------------------------------------------------------------------------------------------
// 변수 선언 && 함수 선언
//---------------------------------------------------------------------------------------------------------------------
const ionRouter = useIonRouter()
const dayjs = inject('$dayjs')

const cnt1 = ref(0)
const cnt2 = ref(0)
const pageInfo = ref({
  total: 0
})
const datas = ref([])

const purchaseList = async () => {
  const params = {
    pageNum: 1,
    pageSize: 9999
  }
  const response = await getPurchaseList(params)
  const { results, count } = response.data
  datas.value = results
  pageInfo.value.total = count
  cnt1.value = datas.value.filter((item) => dayjs(item.order_date).format('YYYY-MM-DD') == dayjs().format('YYYY-MM-DD')).length
  cnt2.value = datas.value.filter((item) => item.order_state_code == 2 && item.acc_state_code == 1).length
}

const presentActionSheet = async () => {
  const actionSheet = await actionSheetController.create({
    header: '선택한 접수상태 목록만 보여집니다.',
    //subHeader: '선택한 항목만 보여집니다',
    buttons: [      
      {
        text: '접수',
        data: {
          action: '1'
        }
      },
      {
        text: '수거',
        data: {
          action: '2'
        }
      },
      {
        text: '완료(입고)',
        data: {
          action: '3'
        }
      },
      {
        text: '취소',
        role: 'cancel',
        data: {
          action: 'cancel'
        }
      }
    ]
  })

  await actionSheet.present()
  const res = await actionSheet.onDidDismiss()

  if(res.role != 'cancel') {    
    const params = {
      pageNum: 1,
      pageSize: 9999,
      order_state_code: res.data.action
    }
    
    const response = await getPurchaseList(params)
    const { results, count } = response.data
    datas.value = results
    pageInfo.value.total = count
  }

}

const onDetail = (item) => {
  const data = {
    id : item.id, 
    order_nm: item.order_nm, 
    order_tel_no: item.order_tel_no, 
    bank_nm: item.bank_nm, 
    acc_nm : item.acc_nm,
    acc_num : item.acc_num,
    acc_state_code : item.acc_state_code,
    acc_state_nm : item.order_state_code === '1'? '접수' : item.order_state_code === '2' ? '수거': '완료',
    description : item.description
  }
  ionRouter.push({name : 'purchaseDetails', query : data})
}

//---------------------------------------------------------------------------------------------------------------------
// life Cycle
//---------------------------------------------------------------------------------------------------------------------
onMounted(() => {
  purchaseList()
})
</script>
